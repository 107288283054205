.App {
  background: #E5E5E5;
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.menu-figma {
	position: absolute;
	width: 211.98px;
	height: 31.85px;

	font-family: Ubuntu;
	font-style: normal;
	font-weight: bold;
	font-size: 26.8711px;
	line-height: 31px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.015em;
	text-transform: capitalize;

	color: #FFFFFF;
}

.Menu-header {
  background-color: #222;
  /* height: 150px; */
  /* padding: 20px; */
  color: white;
}


.Menu-logo {
  -webkit-animation: Menu-logo-spin infinite 20s linear;
          animation: Menu-logo-spin infinite 20s linear;
  height: 20px;
}

@-webkit-keyframes Menu-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes Menu-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.Stats-sized {
  height: 64px;
  width: 64px;
}

.container_stats {
  /* height: 200px; */
  position: relative;
  /* border: 3px solid green; */
}

.center_stats {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #9099ae;
}

