.menu-figma {
	position: absolute;
	width: 211.98px;
	height: 31.85px;

	font-family: Ubuntu;
	font-style: normal;
	font-weight: bold;
	font-size: 26.8711px;
	line-height: 31px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.015em;
	text-transform: capitalize;

	color: #FFFFFF;
}

.Menu-header {
  background-color: #222;
  /* height: 150px; */
  /* padding: 20px; */
  color: white;
}


.Menu-logo {
  animation: Menu-logo-spin infinite 20s linear;
  height: 20px;
}

@keyframes Menu-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
