.Stats-sized {
  height: 64px;
  width: 64px;
}

.container_stats {
  /* height: 200px; */
  position: relative;
  /* border: 3px solid green; */
}

.center_stats {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
